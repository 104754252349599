import React, { useState, createContext, useContext, useMemo, useEffect, useRef } from 'react'

const RootConfigContext = createContext({ showFooter: true })

export default function RootConfigProvider({ children }) {
  const [showFooter, setShowFooter] = useState(true)
  return (
    <RootConfigContext.Provider
      value={useMemo(
        () => ({
          showFooter,
          setShowFooter,
        }),
        [showFooter, setShowFooter]
      )}
    >
      {children}
    </RootConfigContext.Provider>
  )
}

export function useRootConfig() {
  return useContext(RootConfigContext)
}

export function useRootConfigRef(extraConfigProps = {}) {
  const config = useRootConfig()

  const configRef = useRef({})
  useEffect(() => {
    configRef.current = { ...config, ...extraConfigProps }
  }, [config, extraConfigProps])

  return configRef
}
