export const legalDocs = [
  {
    // used in keys to lookup translations
    name: 'privacy',
    // default en nav text, translation fallback
    navText: 'Privacy Notice',
    // path on our familySearch site
    url: 'privacy',
    // the doc path on the church site
    docPath: 'familysearch-privacy-notice',
  },
  {
    name: 'terms',
    navText: 'Terms of Use',
    url: 'terms',
    docPath: 'familysearch-terms',
  },
  {
    name: 'submission',
    navText: 'Content Submission Agreement',
    url: 'familysearch-content-submission-agreement',
    docPath: 'familysearch-trees-submission-agreement',
  },
  {
    name: 'sms',
    navText: 'Text Messaging Terms and Conditions',
    url: 'text-messaging-terms-and-conditions',
    docPath: 'familysearch-text-messaging-terms-and-conditions',
  },
  {
    name: 'uploads',
    navText: 'Upload Guidelines and Policies',
    url: 'familysearch-upload-guidelines-and-policies',
    docPath: 'familysearch-upload-guidelines-and-policies',
  },
  {
    name: 'trademarks',
    navText: 'Guidelines for Use of FamilySearch Trademarks',
    url: 'familysearch-trademark-guidelines',
    docPath: 'familysearch-trademark-guidelines',
  },
]

export const legalDocRoutes = legalDocs.map((doc) => doc.url)

export const getLegalDocDetails = (docPath) => {
  return legalDocs.find((doc) => doc.url === docPath)
}
