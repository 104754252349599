import React from 'react'
import { i18n } from '@fs/zion-locale'
import { useFeatureFlag } from '@fs/zion-flags'

const AboutPage = React.lazy(() => import('./pages/AboutPage/AboutPage'))
const LoadingSpinner = React.lazy(() => import('./shared/components/LoadingSpinner'))

export default function AboutPageRoutes() {
  const dtmulatorOn = useFeatureFlag('shared_addLangCodeToUrl')?.isOn
  if (i18n.language.startsWith('zh')) {
    if (dtmulatorOn) {
      window.location.replace(`https://www.familysearch.org/zh-Hant/chinese/surnames/about`)
    }
    window.location.replace(`https://www.familysearch.org/chinese/surnames/about`)
    return <LoadingSpinner />
  }
  return <AboutPage />
}
