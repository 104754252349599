import React, { createContext, useContext, useState } from 'react'

const HeaderConfigContext = createContext()
const HeaderConfigUpdateContext = createContext()

export function useHeaderConfig() {
  return useContext(HeaderConfigContext)
}

export function useHeaderConfigUpdate() {
  return useContext(HeaderConfigUpdateContext)
}

export default function HeaderConfigProvider({ children, setHeader }) {
  const [headerConfig, setHeaderConfig] = useState({})
  function updateHeaderConfig(newHeader = {}) {
    setHeader?.(newHeader)
    setHeaderConfig(newHeader)
  }

  return (
    <HeaderConfigContext.Provider value={headerConfig}>
      <HeaderConfigUpdateContext.Provider value={updateHeaderConfig}>{children}</HeaderConfigUpdateContext.Provider>
    </HeaderConfigContext.Provider>
  )
}
