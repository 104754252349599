import React, { useState, Suspense } from 'react'
import ReactDOM from 'react-dom'
import '@fs/react-scripts/polyfills'
import * as Sentry from '@sentry/browser'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import { setAppLocales, essentialLocales } from '@fs/zion-locale'
import Root from '@fs/zion-root'
import { Router } from '@fs/zion-router'
import { appPath, sentryDSN, mergeSentryConfig } from '@fs/zion-config'

import { AppLayout } from '@fs/zion-ui'
import { FeatureFlagsProvider } from '@fs/zion-flags'
import { NoticeLoading } from '@fs/zion-icon'
import RootConfigProvider, { useRootConfig } from './shared/components/RootConfig'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './locales'
import HeaderConfig from './HeaderConfig'

// Enable local feature flag development using the file "dev.flags.js"
// If that file exports anything truthy it will initialize feature flags in localhost mode
// and use the flags defined in that file, instead of flags defined in split.
const mockFlags = process.env.NODE_ENV === 'development' ? import('./dev.flags').then((m) => m.default) : undefined

// initialize Sentry for the app
if (sentryDSN) {
  // pass Sentry overrides to mergeSentryConfig
  Sentry.init(
    mergeSentryConfig({
      dsn: sentryDSN,
      integrations: [new TracingIntegrations.BrowserTracing()],
      tracesSampleRate: 0.1,
      ignoreErrors: [
        // Errors that are not actionable, potentially caused by Outlook safe links
        // https://github.com/getsentry/sentry-javascript/issues/3440
        /Non-Error promise rejection captured with value: Object Not Found Matching Id:[0-9]+, MethodName:(browserNotificationOpenUi|soundPlayingChanged)/,
        // No information comes through with this error
        // the only thing that was partially consistent was that a lot of browsers had
        // `navigator.doNotTrack is enabled on the browser` logged
        /UnknownError: Internal error/i,
      ],
      denyUrls: [
        // Error caused by qualtrics
        // Loading chunk 12 failed. (error: https://siteintercept.qualtrics.com/dxjsmodule/12.ebe7e89e19ae15a2ea2c.chunk.js?Q_CLIENTVERSION=2.9.0&Q_CLIENTTYPE=web&Q_BRANDID=www.familysearch.org)
        /https?:\/\/.*siteintercept\.qualtrics\.com/,
      ],
    })
  )
}

// For details about loading translations: https://www.familysearch.org/frontier/docs/develop/i18n
// Explicitly set application-supported locales
// NOTE: Until you add your desired locale via setAppLocales, you will not be able to test the locale via the ?locale param
setAppLocales(essentialLocales)

// NOTE: The home page is on the `/` route, or the `/{locale}` route, so we need the base
// to be either of those, since the home links to the other pages. (We don't want the basename
// to be different if you come in through the home page vs a different page). By keeping the locale
// in the base it means we don't have to include it in the links from one page in the app
// to another page in the app.
let base = appPath ? new URL(appPath).pathname : ''
const localeMatch = window.location.pathname.match(/^\/[a-z]{2,3}|[a-z]{2,3}-[a-zA-Z-]+($|\/.*$)/)
if (window.location.pathname === '/') {
  base = ''
} else if (localeMatch) {
  base = localeMatch[1]
}

const FrontierRoot = () => {
  const { showFooter } = useRootConfig()
  const [useFatFooter, setUseFatFooter] = useState(false)
  const { pathname } = window?.location || {}
  const isSharableRoute = pathname?.includes('/sharable/') || false
  const [open, setOpen] = useState(false)
  const [header, setHeader] = useState({ hidden: isSharableRoute })
  const footer = { useFatFooter, hidden: !showFooter || isSharableRoute }
  const [alertBannerProps, setAlertBannerProps] = useState({
    message: '',
    dismissText: 'Dismiss',
    type: 'info',
  })
  const myAlertBannerProps = {
    ...alertBannerProps,
    onDismiss: (closeBanner) => closeBanner(),
    open,
    setOpen,
  }

  return (
    <React.StrictMode>
      <Suspense fallback={<NoticeLoading />}>
        <FeatureFlagsProvider mockFlags={mockFlags}>
          <Router basename={base}>
            <HeaderConfig setHeader={setHeader}>
              <Root
                analytics={!navigator.userAgent.includes('prerender')}
                alertBannerProps={myAlertBannerProps}
                header={header}
                footer={footer}
              >
                <AppLayout fullWidth>
                  <App setUseFatFooter={setUseFatFooter} {...{ setAlertBannerProps, setAlertBannerOpen: setOpen }} />
                </AppLayout>
              </Root>
            </HeaderConfig>
          </Router>
        </FeatureFlagsProvider>
      </Suspense>
    </React.StrictMode>
  )
}

ReactDOM.render(
  <RootConfigProvider>
    <FrontierRoot />
  </RootConfigProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
