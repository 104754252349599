import React, { useEffect } from 'react'
import { Switch, Route, NotFound, Redirect, useLocation, useQueryParams, AuthRoute } from '@fs/zion-router'
import { trackEvent } from '@fs/zion-analytics'
import ErrorBoundary from '@fs/zion-error-boundary'
import { i18n } from '@fs/zion-locale'
import { useFeatureFlag } from '@fs/zion-flags'
import { preCachePreferences } from '@fs/zion-preferences'
import { useUser } from '@fs/zion-user'
import { lazily } from 'react-lazily' // Allow for lazy-loading of named exports
import { targetEnv } from '@fs/zion-config'
import QueryClientProviderWrapper from './QueryClientProviderWrapper'
import { legalDocRoutes } from './pages/LegalPages/legalDocs'
import AboutPageRoutes from './aboutPageRoutes'

const { ChineseNLIHP } = lazily(() => import('@fs/home-chinese-pages'))

const SiteMap = React.lazy(() => import('./pages/SiteMap'))
const SponsorshipPage = React.lazy(() => import('./pages/SponsorshipPage/SponsorshipPage'))
const BlankPage = React.lazy(() => import('./pages/BlankPage/Blank'))
const NLIHP = React.lazy(() => import('./pages/NLIHP/NLIHP'))
const LIHP = React.lazy(() => import('./pages/LIHPProject/LIHP'))
const OrdinancesProject = React.lazy(() => import('./pages/OrdinancesProject'))
const SharablePage = React.lazy(() => import('./pages/LIHPProject/pages/SharablePage'))
const InviteFriendsSharePage = React.lazy(() => import('./pages/LIHPProject/pages/InviteFriendsSharePage'))
const SinglePostViewPage = React.lazy(() => import('./pages/LIHPProject/pages/SinglePostViewPage'))
const LoadingSpinner = React.lazy(() => import('./shared/components/LoadingSpinner'))
const TreebudsPage = React.lazy(() => import('./pages/TreebudsProject/TreebudsPage'))
const TreebudsPidPage = React.lazy(() => import('./pages/TreebudsProject/TreebudsPidPage'))
const MyFamily = React.lazy(() => import('./pages/MyFamilyPage'))
const LegalPages = React.lazy(() => import('./pages/LegalPages/LegalPages'))
const CookiePage = React.lazy(() => import('./pages/CookiePage'))
const LocalePage = React.lazy(() => import('./pages/LocalePage/LocalePage'))

// Wrapper component to allow the preference-fetching hook to run *before* the app loads, so that we catch everything on initial load, instead of piecemeal.
const TopLevelAppWrapper = ({ children }) => {
  // Pre-cache the preferences commonly used in the app with a single call
  preCachePreferences(
    'help-tutorial.show-ad-prefs',
    'home.familyFeed.hideToggle',
    'home.recommendedTasks.filters.records',
    'home.recommendedTasks.filters.temple',
    'home.task.dismissedMissingInfo',
    'home.task.dismissedTreeHoles',
    'home.task.hintClosed',
    'home.task.howToStartClosed',
    'home.task.TFHCTrainingDismissed',
    'to-do-list.card-open',
    'tree.showLDSTempleInfo',
    'tree.showNamesInRomanScript',
    'match.service'
  )

  return children
}

function App({ setUseFatFooter }) {
  const { signedIn, userLoading } = useUser()
  // The pathname must come from window.location.pathname and not from useLocation so that the basename will be included
  const pathname = window?.location?.pathname
  const { search, hash, pathname: locationPathname } = useLocation()
  const languagePatternString = '([a-z]{2,3}|[a-z]{2,3}-[a-zA-Z-]+)'
  const languagePattern = new RegExp(`^/${languagePatternString}(/|$)`, 'i')
  const langRedirect = pathname && !pathname.match(languagePattern)
  const { isOn: ordinancesPageFlagOn } = useFeatureFlag('home_shared_ordinancesPage')
  const { treatment: ordinancesABTest } = useFeatureFlag('home_shared_ordinancesAB')
  const { isOn: localePageFlagOn } = useFeatureFlag('home_localePage')
  const { isOn: showFatFooter } = useFeatureFlag('home_showFatFooter')
  const ordinancesPageTest = ordinancesABTest === 'ordinancesPage' || ordinancesABTest === 'both'

  const homePath = '(home|home-react)?'
  const localePath = `${languagePatternString}`
  const matchRootPath = `/${localePath}/${homePath}`

  // removing /home on/languageCode/home here since we can't detect that on the server due to DTM stripping info.
  const dtmulatorOn = useFeatureFlag('shared_addLangCodeToUrl')?.isOn
  const localeOnHome = window.location.pathname.match(/^(\/[a-z]{2,3}|[a-z]{2,3}-[a-zA-Z-]+)(?:\/home\/)?$/)?.[1]
  if (dtmulatorOn && targetEnv !== 'local' && localeOnHome) {
    window.location.assign(`${localeOnHome}/`)
  }

  useEffect(() => {
    const fatFooterPages =
      (signedIn === false && locationPathname === '/') ||
      locationPathname.includes('/about') ||
      locationPathname.includes('/locale')
    if (!userLoading && fatFooterPages) {
      trackEvent({
        event_name: showFatFooter ? `Fat Footer Viewed` : `Minimum Footer Viewed`,
        link_page: locationPathname,
      })
    }
    if (showFatFooter && fatFooterPages) {
      setUseFatFooter(true)
    } else {
      setUseFatFooter(false)
    }
  }, [showFatFooter, signedIn, locationPathname, setUseFatFooter, userLoading])

  return (
    <ErrorBoundary>
      <TopLevelAppWrapper>
        <QueryClientProviderWrapper>
          <Switch>
            {langRedirect && <Redirect to={`/${i18n.language}${pathname}${search}${hash}`} />}

            <Route path={`${matchRootPath}/sharable/invite-friends`}>
              <SharablePage sharableComponent={InviteFriendsSharePage} />
            </Route>

            <Route path={`${matchRootPath}/about`}>
              <AboutPageRoutes />
            </Route>
            <Route path={`${matchRootPath}/sponsorship`} component={SponsorshipPage} />
            <Route path={`${matchRootPath}/blank`} render={() => <BlankPage />} />
            <Route path={`${matchRootPath}/site-map`} component={SiteMap} />
            <Route path={`${matchRootPath}/myfamily`} component={MyFamily} />
            <Route path={`${matchRootPath}/cookies`} component={CookiePage} />
            {localePageFlagOn && <Route path={`${matchRootPath}/locale`} component={LocalePage} />}
            <Route path={`${matchRootPath}/legal/:docUrl(${legalDocRoutes.join('|')})`} component={LegalPages} />
            <AuthRoute path={`${matchRootPath}/beta/treebuds/:pid`} component={TreebudsPidPage} />
            <AuthRoute path={`${matchRootPath}/beta/treebuds`} component={TreebudsPage} />

            {(ordinancesPageFlagOn || ordinancesPageTest) && (
              <Route path={`${matchRootPath}/ordinances`} render={() => <OrdinancesProject />} />
            )}

            <Route path={matchRootPath} exact>
              <HomePageRoutes matchRootPath={matchRootPath} />
            </Route>
            <Route path={`${matchRootPath}/(feed|friends|tasks|recents|to-do)`}>
              <HomePageRoutes matchRootPath={matchRootPath} />
            </Route>

            <Route component={NotFound} />
          </Switch>
        </QueryClientProviderWrapper>
      </TopLevelAppWrapper>
    </ErrorBoundary>
  )
}

function HomePageRoutes() {
  const { isOn: showChineseNLIHP } = useFeatureFlag('chinese_homeReact_englishChineseHomePage')
  const { query } = useQueryParams()
  const singlePost = Boolean(query.post)
  const { userLoading, signedIn } = useUser()
  const { pathname } = useLocation()
  if (userLoading) {
    return <LoadingSpinner />
  }
  if (!signedIn) {
    if (singlePost) {
      return <SinglePostViewPage publicPost unauthenticated />
    }
    if (/(feed|friends|tasks|recents|to-do)/i.test(pathname)) {
      typeof window !== 'undefined' &&
        window.location.replace(`/auth/familysearch/login?returnUrl=${encodeURIComponent(window.location.href)}`)
      return <LoadingSpinner />
    }
    if (i18n.language.startsWith('zh') || showChineseNLIHP) {
      return <ChineseNLIHP />
    }
    return <NLIHP />
  }
  return <LIHP />
}

export default App
