import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

export function retry(failureCount, error) {
  if (error.status === 404) {
    return false
  }
  if (failureCount < 2) {
    return true
  }
  return false
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry,
    },
  },
})

// Note:
// > By default, React Query Devtools are only included in bundles when process.env.NODE_ENV === 'development',
// > so you don't need to worry about excluding them during a production build.
export default function QueryClientProviderWrapper({ children, showDevTools }) {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {showDevTools && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  )
}
